import React from 'react';
import numberHelper from '../../../helpers/functions/number.helper';
import { useSelector } from 'react-redux';

const PurchaseTaxes = ({ park_taxes, taxes, }) => {
    const ticketRedux = useSelector(state => state.ticket);
    const currency = ticketRedux?.park?.currency?.symbol;
    return (
        <>
            {
                park_taxes?.map((park_tax, index) => {
                    const { park_tax_id, name, percentage, value } = park_tax;
                    const cal_tax = taxes?.find(x => x.park_tax_id === park_tax_id);
                    const tax_amount = cal_tax?.amount ?? 0;
                    return (
                        <div key={index}>
                            <div style={{display:'flex',  width:'100%'}}>
                                <div>
                                    <span style={{color:'var(--secondary-text-color)'}}>{`${name}`}</span>
                                </div>
                    
                                <div style={{marginLeft:'auto'}}>
                                    <span className='ticket-label' style={{fontSize:14,}}>
                                        {`${currency}${numberHelper?.commaNumber(tax_amount, 2)}`}
                                    </span>
                                </div>
                            </div>
                        </div>
    
                    )
                })
            }
        </>
    );
}

export default PurchaseTaxes;