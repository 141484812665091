import { Button, Col, Drawer, Row, Space } from 'antd';
import React, { useEffect, useRef, useState } from 'react';
import OpenDateTicketType from './open_date_ticket_type';
import { useDispatch, useSelector } from 'react-redux';
import { set_park, set_special_link } from '../../../actions/ticket.action';
import parkService from '../../../services/config/park.service';
import { set_purchase_detail } from '../../../actions/purchase.action';
import axios from 'axios';
import OpenDateParkApplicableTicketTypeItem from './open_date_park_applicable_ticket_type_item';
import globalHelper from '../../../helpers/functions/global.helper';
import OpenDateVisitor from './open_date_visitor';
import OpenDateLiabilityWaiver from './open_date_liability_waiver';
import PurchaseButton from '../../purchase/components/purchase_button';
import OpenDatePurchaseButton from './open_date_purchase_button';
import OpenDatePurchaseSummary from './open_date_purchase_summary';
import SummaryDateCalendar from '../../purchase/components/summary_date_calendar';
import OpenDateSummaryDateCalendar from './open_date_summary_date_calendar';

const OpenDatePurchaseLayout = ({ park_code, }) => {
    const purchaseRedux = useSelector(state => state?.purchase);
    const ticketRedux = useSelector(state => state?.ticket);
    const dispatch = useDispatch();

    // reference
    const ticket_type_ref = useRef();
    const ticket_user_type_ref = useRef();

    // memo
    const bannerHeight = (ticketRedux?.park?.banner_text ? (globalHelper?.isMobileScreen() ? 64 : 40) : 0);

    const [state, setState] = useState({
        loading : true,
        park : null,

        // special link
        special_link : null,
        special_link_expired : false,
        special_link_used : false,

        open_summary_drawer : false,

        // set final amount for purchase button
        total_amount : 0,
    })

    const fetchPark = async () => {
        setState(state => ({...state, loading : true}));
        const parkResponse = await parkService.getPark({
            park_code : park_code,
            is_server_maintenance : false,
        });
        const park_id = parkResponse?.parks?.park_id
        
        dispatch(set_purchase_detail({ park_id : park_id, open_date : true, }));
        dispatch(set_park(parkResponse?.parks))
        setTimeout(() => {
            setState(state => ({...state,
                loading : false,
                park : parkResponse?.parks,
            }))
        }, 500)
    }

    const openSummary = (open) => {
        setState(state => ({...state, open_summary_drawer : open, }));
    }

    useEffect(() => {
        fetchPark();
    }, [])

    useEffect(() => { //set header of axios
        axios.defaults.headers.common['park_id'] = state?.park?.park_id;

        return ()=>{
            axios.defaults.headers.common['park_id'] = null;
        }
    }, [state?.park])

    useEffect(() => {
        if(purchaseRedux?.ticket_type_id){
            const headerHeight = (document.querySelector('header')?.offsetHeight ?? 0) + bannerHeight;
            const topOffset = ticket_user_type_ref.current.getBoundingClientRect().top + window.scrollY - (headerHeight + 40);
            window.scrollTo({ top : topOffset, behavior: 'smooth' });
        }
    }, [purchaseRedux?.ticket_type_id])

    return (
        <>
            <div style={{ paddingBlock : 12, }}>
                <Row justify={'center'}>
                    <Col xs={24} md={18} xl={14} span={14}>
                        <div>
                            <div ref={ticket_type_ref}>
                                <OpenDateTicketType park={state?.park} />
                            </div>
                            <div ref={ticket_user_type_ref}>
                                {
                                    purchaseRedux?.ticket_type_id && (
                                        <>
                                            <OpenDateParkApplicableTicketTypeItem ticket_type={state?.park?.ticket_types?.find(x => x.ticket_type_id === purchaseRedux?.ticket_type_id)} />
                                            <OpenDateVisitor />
                                            <OpenDateLiabilityWaiver park={state?.park} />

                                            <div style={{ marginTop : 48, }}>
                                                {/* <OpenDatePurchaseButton /> */}
                                                <Button
                                                onClick={() => openSummary(true)}
                                                // onClick={purchaseTicket}
                                                size='large'
                                                type="primary"
                                                style={{width:'100%', border:'none'}}
                                                // disabled={is_disabled}
                                                // loading={state?.save_loading}
                                                >
                                                    <span style={{color: "var(--main-text-color)" , fontWeight:500}}>Review Order</span>
                                                </Button>
                                            </div>
                                        </>
                                    )
                                }
                            </div>
                        </div>
                    </Col>
                </Row>
            </div>

            <Drawer
            open={state?.open_summary_drawer}
            width={500}
            // motion={false}
            onClose={() => openSummary(false)}
            closable={false}
            styles={{ header : { padding : 0, }, body : { padding : 0, } }}
            title={(
                <>
                    <div style={{textAlign:'left', ...(state?.park?.cover_image && ({ backgroundImage : `url(${state?.park?.cover_image})`, backgroundPosition : 'center', backgroundSize : 'cover', backgroundRepeat :'no-repeat', borderRadius : '6px 6px 0px 0', })) }}>
                        <div {...state?.park?.cover_image && ({ className : 'card-overlay', })} style={{ padding:'12px 24px', }}>
                            <div style={{display:'flex', alignItems:'center'}}>
                                <div>
                                    <Space align='start' size={20}>
                                        <OpenDateSummaryDateCalendar />
                                        {/* <SummaryDateCalendar date={globalHelper.parseDate(purchaseRedux?.visit_date)} /> */}
                                        <div>
                                            <div>
                                                <span style={{fontSize:14, color : (state?.park?.cover_image ? ('#fff') : ('var(--secondary-text-color)')), fontWeight:'normal'}}>
                                                    {`Ticket Type : ${"asd" ?? 'N/A'}`}
                                                </span>
                                            </div>
                                            <div>
                                                <Space wrap align='center'>
                                                    {/* <div>
                                                        <span style={{fontSize:14, color : (park?.cover_image ? ('#fff') : ('var(--secondary-text-color)')), fontWeight:'normal'}}>
                                                            {`Visit Date : ${(`${purchaseRedux?.visit_date ?? 'N/A'} ${ticket_types?.ParkApplicableTicketType?.date_range > 0 ? `to ${moment(globalHelper.parseDate(purchaseRedux?.visit_date))?.add((ticket_types?.ParkApplicableTicketType?.date_range ?? 0), 'days')?.format("DD/MM/YYYY")}` : ''}` ?? 'N/A')}`}
                                                        </span>
                                                    </div> */}
                                                    {/* {
                                                        preference &&
                                                        (
                                                            <>
                                                                <div>
                                                                    <Tag style={{border : 'none', color : 'var(--main-color)' }} color={'var(--lighten-main-color)'}>
                                                                    {`${preference?.preference_group ? (`${preference?.preference_group?.name} - `) : ''} ${(preference?.name)}`}
                                                                    </Tag>
                                                                </div>
                                                            </>
                                                        )
                                                    } */}
                                                </Space>
                                            </div>
                                        </div>
                                    </Space>
                                </div>
                            </div>
                        </div>
                    </div>
                </>
            )}
            footer={(
                <>
                    <PurchaseButton is_checkout park={state?.park} final_price={state?.total_amount} />
                </>
            )}
            >
                <OpenDatePurchaseSummary key={state?.open_summary_drawer} park={state?.park} setPurchaseAmount={(total_amount) => setState(state => ({...state, total_amount, }))} />
            </Drawer>
        </>
    );
}

export default OpenDatePurchaseLayout;