import axios from 'axios';
import serviceHelper from '../../helpers/functions/service.helper';

async function getPark(query){
    const queryString = serviceHelper.queryString(query);
    const parkResponse = await axios.get(`config/park?${queryString}`, { withCredentials : true });
    return parkResponse?.data;
}

async function getParkApplicableTicketTypes(query){
    const queryString = serviceHelper.queryString(query);
    const parkApplicableTicketTypeResponse = await axios.get(`config/park/park_applicable_ticket_type?${queryString}`, { withCredentials : true });
    return parkApplicableTicketTypeResponse?.data;
}

async function getParkApplicableTicketTypeitems(query){
    const queryString = serviceHelper.queryString(query);
    const parkApplicableTicketUserResponse = await axios.get(`config/park/park_applicable_ticket_type_items?${queryString}`, { withCredentials : true });
    return parkApplicableTicketUserResponse?.data;
}

async function getAddOn(query){
    const queryString = serviceHelper.queryString(query);
    const addOnResponse = await axios.get(`config/park/add_on?${queryString}`, { withCredentials : true });
    return addOnResponse?.data;
}

async function checkPeakDays(query){
    const queryString = serviceHelper.queryString(query);
    const peakDayResponse = await axios.get(`config/park/peak_days?${queryString}`, { withCredentials : true });
    return peakDayResponse?.data;
}

async function getCapacity(query){
    const queryString = serviceHelper.queryString(query);
    const capacityResponse = await axios.get(`config/park/capacity?${queryString}`, { withCredentials : true });
    return capacityResponse?.data;
}

async function getTnc(park_id){
    const liabilityWaiverResponse = await axios.get(`config/park/${park_id}/tnc`, { withCredentials : true });
    return liabilityWaiverResponse?.data;
}

export default {
    getPark,
    getParkApplicableTicketTypes,
    getParkApplicableTicketTypeitems,
    getAddOn,
    checkPeakDays,
    getCapacity,
    getTnc,
}