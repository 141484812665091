import { Alert, Avatar, Button, Col, DatePicker, Divider, Dropdown, Form, Image, Input, InputNumber, Modal, Row, Select, Space, Tag, Upload, message, notification } from 'antd';
import React, { useEffect, useState } from 'react';
import EntranceMotion from '../../../helpers/components/entrance_motion';
import globalHelper from '../../../helpers/functions/global.helper';
import ticketService from '../../../services/ticketing/ticket.service';
import integrationService from '../../../services/integration/integration.service';
import { LazyLoadImage } from 'react-lazy-load-image-component';
import OpenDatePicker from './open_date_picker';
import { useHistory } from 'react-router-dom';
import Compressor from 'compressorjs';
import { useSelector } from 'react-redux';

// kidzania integration
import IntegrationPazzport from '../integration/pazzport';
import kidzaniaService from '../../../services/integration/kidzania.service';
import { CameraOutlined, PictureOutlined } from '@ant-design/icons';
import MobileDetect from 'mobile-detect';

const { Option } = Select;
const DetailTicketUser = ({ ticket_user, token, }) => {
    const ticketRedux = useSelector(state => state?.ticket);
    const history = useHistory();
    const [form] = Form.useForm();
    const { ticket_id, is_register, ticket_user_type, ticket_user_no, entrance_transactions, ticket } = ticket_user ?? {};
    const is_checked_in = entrance_transactions?.length > 0;
    const acceptable_file_format = ['image/jpeg', 'image/png'];
    const isAndroid = (new MobileDetect(window.navigator.userAgent)?.os() === 'AndroidOS');
    const isWebView = (/(android)/i.test(navigator.userAgent) && /(wv)|(webview)/i.test(navigator.userAgent));
    
    const [state, setState] = useState({
        file : null,
        save_loading : false,

        // integration
        park_api_types : [],
    });

    const [fields, setFields] = useState([]);
    const [option, setOption] = useState({
        age : [],
    })

    const beforeUpload = async (file) => {
        const is_image = acceptable_file_format?.find(x => x == file?.type);

        if(is_image){
            const uploaded_file = await new Promise((resolve, reject) => {
                const reader = new FileReader();
                reader.readAsDataURL(file);
                reader.onload = () => {
                    file.base64 = reader.result.split(',')[1];
                    resolve(file);
                };
                reader.onerror = (error) => reject(error);
            });
    
            const base64Image = `data:image/jpeg;base64,${uploaded_file?.base64}`
            setState(state => ({...state, file : base64Image}));
        }else{
            errorModal("Please upload in image format");
        }
        
        return false;
    }

    const updateTicketUser = async () => {
        const { age, gender, name, visit_date, avatar } = form.getFieldsValue();

        // Create an object to store the destructured values
        const dynamic_fields = {};
        // Iterate through the array and destructure the values
        for (const field of fields) {
            const { key } = field;
            dynamic_fields[key] = form.getFieldsValue()[key];
        }

        setState(state => ({...state, save_loading : true}));
        const file = avatar?.file ?? null;
        let compressed_file = null;
        if(file){
            const compressor = new Promise((resolve, reject) => 
                new Compressor(file, {
                    quality : 0.8,
                    height : 800,
                    width : 600,
                    mimeType : 'image/jpeg',
                    success : resolve,
                    error : reject,
                    // convertSize: 500000,
                })
            )
            compressed_file = await compressor;
        }
        
        const updateTicketUserResponse = await ticketService.updateTicketUser(ticket_user_no, {
            name, age, gender, visit_date,
            ...(avatar && ({avatar : compressed_file,})),
            ...dynamic_fields,
        }, fields).catch(err => {
            const message = err?.response?.data?.message;
            errorModal(message,);
            setState(state => ({...state, save_loading : false, file : null,}))
        });

        if(updateTicketUserResponse){
            const response_token = updateTicketUserResponse?.ticket?.token;

            // integration
            await integrateUpdateVisitDate({
                ticket_id : ticket_id,
                ticket_user_id : ticket_user?.ticket_user_id, 
                visit_date,
            }).catch(err => {
                setState(state => ({...state, save_loading : false}));
            });

            setTimeout(() => {
                setState(state => ({...state, save_loading : false}));
    
                // success notification
                notification.success({
                    message : 'Success',
                    description : 'You have updated your ticket detail',
                });

                //redirect back if got url
                if(response_token == token){
                    history.goBack();
                }
            }, 500);
        }
    }

    const integrateUpdateVisitDate = async ({ticket_id, ticket_user_id, visit_date, }) => {
        let result;
        const update_date_integration = state?.park_api_types?.find(x => x.condition === 'UPDATE_DATE');
        const { api_type } = update_date_integration;
        if(update_date_integration && visit_date){
            switch(api_type?.fn_code){
                case 'KIDZOFT_ACT' :
                    result = await kidzaniaService.updateVisitDate({
                        ticket_user_ids : [ticket_user_id],
                        ticket_id, visit_date,
                    });
                    break;
                default :
                    result = null;
                    break;
            }
        }
    }

    const errorModal = (message,) => {
        Modal.error({
            icon : null,
            title : message,
            content : (
                <>
                    <div style={{padding : 24, textAlign : 'center'}}>
                        <Image preview={false} src='/cibanners/selfie-person.png' style={{maxWidth : 180,}} />
                    </div>
                    <div>
                        <div>
                            <span className='header-font' style={{fontWeight:500, fontSize:16}}>
                                {message}
                            </span>
                        </div>
                        <div>
                            <span>Please upload another photo or make a selfie with your device’s camera. We don’t save it!</span>
                        </div>
                    </div>
                </>
            ),
            okButtonProps : { style : { background : 'var(--main-color)', color : '#fff' } }
        })
        form.setFieldsValue({ avatar : null });
    }

    //initialize
    const initializeTicketUser = async () => {
        //set option
        const max_age = (ticket_user_type?.max_age ?? 100);
        const min_age = (ticket_user_type?.min_age ?? 0);
        const age_range = max_age - min_age;
        let age_options = [];
        for(let i = min_age; i <= max_age; i++){
            age_options?.push({ value : i, label : i });
        }
        setOption(option => ({...option, age : age_options}));

        const { name, gender, age, image_url, ticket_user_fields } = ticket_user ?? {};

        // destructure dynamic fields
        // Create an object to store the destructured values
        const dynamic_fields = {};
        // Iterate through the array and destructure the values
        for (const field of ticket_user_fields) {
            const { key, FieldResponse : field_response } = field;
            dynamic_fields[key] = field_response?.response;
        }

        form.setFieldsValue({
            name, gender, age,
            ...dynamic_fields,
        })
        setState(state => ({...state, file : image_url}));
    }

    const initializeFields = async () => {
        const ticketFieldResponse = await ticketService?.getTicketField(ticket_user_no);
        const integrationResponse = await integrationService?.getParkIntegrations(ticket?.park_id);

        setState(state => ({...state, park_api_types : integrationResponse?.integrations}));
        setFields(ticketFieldResponse?.ticket_user_fields);
    }

    useEffect(() => {
        initializeFields()?.then(() => {
            initializeTicketUser();
        })
        
    }, [ ticket_user ]);

    // const checkCameraAccess = async () => {
    //     if (navigator.mediaDevices && navigator.mediaDevices.getUserMedia) {
    //         // Browser supports getUserMedia
    //         setCameraAccess(true)
    //     }
    // }

    // useEffect(() => {
    //     checkCameraAccess();
    // }, [])

    return (
        <>
            <div className='save-ticket-user-content'>
                <div>
                    <span
                    className='ticket-label ticket-title h1'
                    style={{
                        fontWeight:'bold',
                        fontSize: globalHelper?.isMobileScreen() ? 20 : 24,
   
                    }}
                    >
                        {`Upload Selfie`}
                    </span>
                </div>

                <div style={{marginTop:40}}>
                    <Form
                    id="personal-detail-form"
                    // className='personal-detail-form'
                    layout='vertical'
                    form={form}
                    // style={{display:'flex', justifyContent:'center', width:'100%'}}
                    onFinish={updateTicketUser}
                    >
                        <div>
                            <EntranceMotion initialY={-100} duration={1}>
                                {
                                    isAndroid ?
                                    (
                                        <>
                                            <div style={{ width : 'fit-content', margin:'auto', }}>
                                                <Dropdown
                                                disabled={is_checked_in}
                                                trigger={['click']}
                                                menu={{ items : [{
                                                    key: '1',
                                                    style : { padding : 0, },
                                                    label: (
                                                        <>
                                                            <Form.Item className='dropdown-image' name={'avatar'} style={{ marginBottom : 0, minHeight : 0, }}>
                                                                <Upload
                                                                disabled={is_checked_in}
                                                                beforeUpload={beforeUpload}
                                                                name="image"
                                                                data={{
                                                                    ticket_user_id : ticket_user?.ticket_user_id,
                                                                    unique_no : ticket_user?.ticket_user_no,
                                                                }}
                                                                showUploadList={false}
                                                                accept='image/jpeg, image/png'
                                                                style={{ width : '100%', }}
                                                                >
                                                                    <Space style={{ width : '100%', padding : '5px 12px' }}>
                                                                        <PictureOutlined style={{ color : 'var(--secondary-text-color)', }} />
                                                                        <span style={{ color : 'var(--secondary-text-color)', }}>Photo Library</span>
                                                                    </Space>
                                                                </Upload>
                                                            </Form.Item>
                                                        </>
                                                    ),
                                                },
                                                {
                                                    type: 'divider',
                                                },{
                                                    key: '2',
                                                    style : { padding : 0, },
                                                    label: (
                                                        <>
                                                            <div style={{ paddingBlock : 5, }}>
                                                            {
                                                                isWebView ?
                                                                (
                                                                    <>
                                                                        <div onClick={() => window.location = `intent:${window.location.href}#Intent;end`} style={{ paddingInline : 12, }}>
                                                                            <Space style={{ width : '100%', opacity : 0.5, }}>
                                                                                <CameraOutlined style={{ color : 'var(--secondary-text-color)', }} />
                                                                                <span style={{ color : 'var(--secondary-text-color)', }}>Take Photo</span>
                                                                            </Space>
                                                                            <div style={{ maxWidth : 200, marginTop : 4, }}>
                                                                                <span style={{ fontSize : 12, color : 'var(--secondary-text-color)', }}>
                                                                                    Your device camera is not supported here. Please tap on "Take Photo" to capture a selfie from your default web browser
                                                                                </span>
                                                                            </div>
                                                                        </div>
                                                                    </>
                                                                )
                                                                :
                                                                (
                                                                    <>
                                                                        <Form.Item
                                                                        className='dropdown-image' name={'avatar'}
                                                                        style={{ marginBottom : 0, minHeight : 0, ...(isWebView && ({ opacity : 0.5, })) }}
                                                                        // if camera is not accessible, then show message to ask user open in anther device / browser
                                                                        >
                                                                            <Upload
                                                                            disabled={isWebView}
                                                                            beforeUpload={beforeUpload}
                                                                            name="image"
                                                                            data={{
                                                                                ticket_user_id : ticket_user?.ticket_user_id,
                                                                                unique_no : ticket_user?.ticket_user_no,
                                                                            }}
                                                                            showUploadList={false}
                                                                            accept='image/*'
                                                                            capture
                                                                            style={{ width : '100%', }}
                                                                            >
                                                                                <Space style={{ width : '100%', paddingInline : 12, }}>
                                                                                    <CameraOutlined style={{ color : 'var(--secondary-text-color)', }} />
                                                                                    <span style={{ color : 'var(--secondary-text-color)', }}>Take Photo</span>
                                                                                </Space>
                                                                            </Upload>
                                                                        </Form.Item>
                                                                    </>
                                                                )
                                                            }
                                                            </div>
                                                        </>
                                                    ),
                                                }
                                                ]}}
                                                >
                                                    <Form.Item>
                                                        <div className="border-circle" style={{border : '2px solid var(--main-color)', borderRadius:'50%', padding:12, cursor : 'pointer' }}>
                                                            <Avatar size={140} src={
                                                                <LazyLoadImage
                                                                src={state?.file ?? "/cibanners/empty-user.png"}
                                                                />
                                                            } 
                                                            />
                                                        </div>
                                                    </Form.Item>
                                                </Dropdown>
                                            </div>
                                        </>
                                    )
                                    :
                                    (
                                        <>
                                            <Form.Item name={'avatar'} style={{marginBottom:12}}>
                                                <Upload
                                                disabled={is_checked_in}
                                                beforeUpload={beforeUpload}
                                                name="image"
                                                data={{
                                                    ticket_user_id : ticket_user?.ticket_user_id,
                                                    unique_no : ticket_user?.ticket_user_no,
                                                }}
                                                showUploadList={false}
                                                className="upload-circle"
                                                accept='image/jpeg, image/png'
                                                // accept='image/*'
                                                // capture="environment"
                                                >
                                                    <div className="border-circle" style={{border : '2px solid var(--main-color)', borderRadius:'50%', padding:12}}>
                                                        <Avatar size={140} src={
                                                            <LazyLoadImage
                                                            src={state?.file ?? "/cibanners/empty-user.png"}
                                                            />
                                                        } 
                                                        />
                                                    </div>
                                                </Upload>
                                            </Form.Item>
                                        </>
                                    )
                                }
                            </EntranceMotion>

                            {
                                ticket_user?.ticket?.is_open_date &&
                                (
                                    <>
                                        <Form.Item
                                        name={'visit_date'}
                                        required={false}
                                        className='ticket_user-form-item'
                                        label={
                                            <>
                                                Visit Date
                                            </>
                                        } 
                                        style={{paddingTop:12,}}
                                        >
                                            {
                                                is_checked_in ? 
                                                (
                                                    <>
                                                        <div style={{textAlign:'start',}}>
                                                            <span style={{color:'var(--secondary-text-color)'}}>{ticket_user?.open_date}</span>
                                                        </div>
                                                    </>
                                                )
                                                :
                                                (
                                                    <>
                                                        <OpenDatePicker park_applicable_ticket_type={ticket_user?.park_applicable_ticket_type} expired_date={ticket_user?.ticket?.expired_date} date={ticket_user?.open_date} form={form} park={ticket_user?.ticket?.park} />
                                                    </>
                                                )
                                            }
                                        </Form.Item>
                                        <Divider />
                                    </>
                                )
                            }

                            {
                                ticket_user?.ticket?.park?.is_user_detail_required &&
                                (
                                    <>
                                        <Form.Item
                                        name={'name'}
                                        rules={[
                                            {
                                            required: true,
                                            message: 'Please input your name',
                                            },
                                        ]}
                                        required={false}
                                        className='ticket_user-form-item'
                                        label={`Name`} 
                                        // style={{:12,}}
                                        >
                                            {
                                                is_checked_in ?
                                                (
                                                    <>
                                                        <div style={{textAlign : 'start',}}>
                                                            <span style={{color:'var(--secondary-text-color)'}}>{ticket_user?.name}</span>
                                                        </div>
                                                    </>
                                                )
                                                :
                                                (
                                                    <>
                                                        <Input placeholder='John Doe' style={{border:'2px solid #cbd5e0'}} />
                                                    </>
                                                )
                                            }
                                        </Form.Item>
                                        <Form.Item
                                        rules={[
                                            {
                                            required: true,
                                            message: 'Please input your age',
                                            },
                                        ]}
                                        required={false}
                                        className='ticket_user-form-item'
                                        name={'age'}
                                        label={`Age`}
                                        style={{marginTop:12,}}
                                        >
                                            {/* <InputNumber
                                            
                                            // size='large'
                                            placeholder={`${ticket_user_type?.min_age} - ${ticket_user_type?.max_age}`}
                                            type='number'
                                            min={ticket_user_type?.min_age ?? 0}
                                            max={ticket_user_type?.max_age}
                                            style={{border:'2px solid #cbd5e0', width:'100%'}}
                                            /> */}

                                            {
                                                is_checked_in ?
                                                (
                                                    <>
                                                        <div style={{textAlign : 'start',}}>
                                                            <span style={{color:'var(--secondary-text-color)'}}>{ticket_user?.age}</span>
                                                        </div>
                                                    </>
                                                )
                                                :
                                                (
                                                    <>
                                                        <Select
                                                        placeholder={`${ticket_user_type?.min_age} - ${ticket_user_type?.max_age ?? "100+"}`}
                                                        // bordered={false}
                                                        style={{border:'2px solid #cbd5e0', borderRadius: 8,}}
                                                        variant='borderless'
                                                        options={option?.age}
                                                        >
                                                        </Select>
                                                    </>
                                                )
                                            }
                                        </Form.Item>
                                        <Form.Item
                                        rules={[
                                            {
                                            required: true,
                                            message: 'Please input your gender',
                                            },
                                        ]}
                                        required={false}
                                        className='ticket_user-form-item'
                                        name={'gender'}
                                        label={`Gender`}
                                        style={{marginTop:12,}}
                                        >
                                            {
                                                is_checked_in ?
                                                (
                                                    <>
                                                        <div style={{textAlign : 'start',}}>
                                                            <span style={{color:'var(--secondary-text-color)',}}>{ticket_user?.gender == 'M' ? "Male" : "Female"}</span>
                                                        </div>
                                                    </>
                                                )
                                                :
                                                (
                                                    <>
                                                        <Select
                                                        placeholder="Male"
                                                        // size='large'
                                                        bordered={false}
                                                        style={{border:'2px solid #cbd5e0', borderRadius: 8}}
                                                        options={[
                                                            {value : 'M', label : 'Male'},
                                                            {value : 'F', label : 'Female'}
                                                        ]}
                                                        />
                                                    </>
                                                )
                                            }
                                        </Form.Item>
                                        <Divider />
                                    </>
                                )
                            }

                            {/* Dynamic Ticket Fields */}
                            {
                                fields?.length > 0 &&
                                (
                                    <>
                                        {
                                            fields?.map((field, index) => {
                                                const { ticket_user_field_id, key, label, descriptions, placeholder, park_api_type, } = field;
                                                const ticket_user_field = ticket_user?.ticket_user_fields?.find(x => x.ticket_user_field_id == ticket_user_field_id);
                                                const field_response = ticket_user_field?.FieldResponse;
                                                return (
                                                    <div key={index}>
                                                        <Form.Item
                                                        required={false}
                                                        className='ticket_user-form-item'
                                                        name={key}
                                                        label={label}
                                                        style={{marginTop:12,}}
                                                        >
                                                            {
                                                                is_checked_in ?
                                                                (
                                                                    <>
                                                                        <div style={{textAlign : 'start',}}>
                                                                            <span style={{color:'var(--secondary-text-color)',}}>{field_response?.response}</span>
                                                                        </div>
                                                                    </>
                                                                )
                                                                :
                                                                (
                                                                    <>
                                                                        {
                                                                            park_api_type ?
                                                                            (
                                                                                <>
                                                                                    {
                                                                                        park_api_type?.condition === "FETCH_PAZZPORT" ?
                                                                                        (
                                                                                            <>
                                                                                                <IntegrationPazzport field={field} form={form} park_id={ticket?.park_id} />
                                                                                            </>
                                                                                        )
                                                                                        :
                                                                                        (
                                                                                            <>
                                                                                            {/* If there is any custom integration */}
                                                                                            </>
                                                                                        )
                                                                                    }
                                                                                </>
                                                                                
                                                                            )
                                                                            :
                                                                            (
                                                                                <Input placeholder={placeholder} style={{border:'2px solid #cbd5e0'}} />
                                                                            )
                                                                        }
                                                                    </>
                                                                )
                                                            }
                                                        </Form.Item>
                                                        <div style={{marginTop:12,  display:'flex', justifyContent:'center'}}>
                                                            <Alert message={descriptions} style={{ width : 400, border:'none', background : 'var(--secondary-button-color)', color:'var(--secondary-text-color)'}} />
                                                        </div>
                                                    </div>
                                                )
                                            })
                                        }
                                        <Divider />
                                    </>
                                )
                            }
                            

                            {
                                is_checked_in ?
                                (
                                    <>
                                        <div style={{marginTop:42,}}>
                                            <EntranceMotion delay={0.1}>
                                                <Alert message={`You have checked in to ${ticket?.park?.name}`} style={{background:'var(--secondary-button-color)', color:'var(--secondary-text-color)', border:'none'}} />
                                            </EntranceMotion>
                                        </div>
                                    </>
                                )
                                :
                                (
                                    <>
                                        <Form.Item>
                                        {
                                            globalHelper?.isMobileScreen() ?
                                            (
                                                <>
                                                    <div style={{marginTop: 80,}} />
                                                    <div
                                                    className={`footer-save-ticket-user ${!ticketRedux?.ticket_saveable ? 'disabled' : ''}`}
                                                    // onClick={() => setState(state => ({...state, is_drawer : true}))}
                                                    >
                                                        <Button disabled={!ticketRedux?.ticket_saveable} loading={state?.save_loading} onClick={() => form.submit()} type='text' size='large' style={{width:'100%'}}>
                                                            <span style={{fontWeight:500, color:'var(--main-text-color)'}}>Save</span>
                                                        </Button>
                                                    </div>
                                                </>
                                            )
                                            :
                                            (
                                                <>
                                                    <div style={{display:'flex', justifyContent:'center',}}>
                                                        <Button disabled={!ticketRedux?.ticket_saveable} loading={state?.save_loading} onClick={() => form.submit()} className='footer-save-ticket-user' type='primary' size='large'>Save</Button>
                                                    </div>
                                                </>
                                            )
                                        }
                                        </Form.Item>
                                    </>
                                )
                            }
                            
                        </div>

                        
                    </Form>
                </div>

                
            </div>

            
            
        </>
    );
}

export default DetailTicketUser;