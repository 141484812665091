import { Alert, Button, Col, Drawer, Row, Space, Spin, notification } from 'antd';
import React, { useEffect, useState } from 'react';
import globalHelper from '../../../helpers/functions/global.helper';
import EntranceMotion from '../../../helpers/components/entrance_motion';
import MobileDateCalendar from './mobile_date_calendar';
import MobileUserSummary from './mobile_user_summary';
import { useSelector } from 'react-redux';
import DateCheckoutButton from '../components/date_checkout_button';
import moment from 'moment';
import { LoadingOutlined } from '@ant-design/icons';
import PreferenceSelect from '../components/preference_select';

const MobileBottomLayout = ({ticket, loading, is_checked_in, is_open_date, change_date_advance_day, park_applicable_ticket_type_id, }) => {
    const dateRedux = useSelector(state => state?.date);
    const { innerWidth } = window;
    const [state, setState] = useState({
        is_drawer : false,
        step : 0,
        date_diff : 0,

        //input
        visit_date : null,
    })


    const changeNextStep = (step) => {
        setState(state => ({...state, step : step}));
    }

    const openDrawer = () => {
        //before open, check date
        if(state?.date_diff >= change_date_advance_day){
            setState(state => ({...state, is_drawer : true}))
        }
    }

    //check is change date enabled
    const checkDateDiff = () => {
        setState(state => ({...state, loading : true, }))
        const date_diff = moment(ticket?.visit_date)?.startOf('days')?.diff(moment()?.startOf('days'), 'days');
        setState(state => ({...state, date_diff : date_diff, loading : false,}));
    }

    useEffect(() => {
        checkDateDiff();
    }, [ticket?.visit_date])

    return (
        <>
            {
                globalHelper.isMobileScreen() &&
                (
                    <>
                        {/* Expiry Alert */}
                        {
                            !loading &&
                            (
                                <>
                                    <div style={{padding:'0 24px'}}>
                                    {
                                        (state?.date_diff < change_date_advance_day) ?
                                        (
                                            <>
                                                <Alert 
                                                style={{border:'none'}}
                                                message={<span style={{fontWeight:500,}}>Ticket date cannot be changed</span>}
                                                // description="This ticket has been expired, "
                                                type="error"
                                                />
                                            </>
                                        )
                                        :
                                        is_checked_in ?
                                        (
                                            <>
                                                <Alert 
                                                style={{border:'none', background : 'var(--secondary-button-color)'}}
                                                message={<span style={{fontWeight:500,}}>Ticket have been checked in</span>}
                                                // description="This ticket has been expired, "
                                                />
                                            </>
                                        )
                                        :
                                        is_open_date ?
                                        (
                                            <>
                                                <Alert 
                                                style={{border:'none'}}
                                                message={<span style={{fontWeight:500,}}>This ticket is an open date ticket</span>}
                                                />
                                            </>
                                        )
                                        :
                                        (
                                            <>
                                            </>
                                        )
                                    }
                                    </div>
                                </>
                            )
                           
                        }

                        <div
                        className={`footer-checkout-button ${(state?.date_diff < change_date_advance_day || is_checked_in) ? 'disabled' : ''}`}
                        onClick={openDrawer}
                        // onClick={checkTicketRules}
                        >
                            <EntranceMotion initialY={100} duration={1}>
                                <div style={{padding:'.75rem', display:'flex', alignItems:'center', justifyContent:'center'}}>
                                    <div>
                                        <span style={{fontSize:18, fontWeight:500, color:'var(--main-text-color)'}}>Change Date</span>
                                    </div>
                                </div>
                            </EntranceMotion>
                        </div>
                        <Drawer
                        className='purchase-summary-drawer'
                        open={state?.is_drawer}
                        width={innerWidth}
                        height={'auto'}
                        placement="bottom"
                        // closable={false}
                        // mask={false}
                        // closeIcon={false}
                        bodyStyle={{padding:0}}
                        title={(
                            <>
                                {state?.step === 0 ?
                                (
                                    <>
                                        <span>Select Date</span>
                                    </>
                                )
                                :
                                state?.step === 1 ?
                                (
                                    <>
                                        <span>Review Price and Checkout</span>
                                    </>
                                )
                                :
                                (
                                    <>
                                    </>
                                )
                                }
                            </>
                        )}
                        // title={ state?.step === 0 ? "Select Date" : "Review Price and Checkout"}
                        onClose={() => setState(state => ({...state, is_drawer : false,}))}
                        >

                            <div>
                                {
                                    state?.step == 0 ?
                                    (
                                        <>
                                            <div>
                                                <MobileDateCalendar park={ticket?.park} />
                                            </div>
                                            <div style={{padding : '12px 24px'}}>
                                                <PreferenceSelect park_applicable_ticket_type_id={park_applicable_ticket_type_id} ticket={ticket} />
                                            </div>
                                            <div style={{padding:'12px 24px',}}>
                                                <Button
                                                disabled={!dateRedux?.visit_date}
                                                size='large'
                                                type='primary'
                                                style={{width:'100%', color : 'var(--main-text-color)', fontWeight:500}}
                                                onClick={() => changeNextStep(state?.step + 1)}
                                                >
                                                    Next
                                                </Button>
                                            </div>
                                        </>
                                    )
                                    :
                                    (
                                        <>
                                            <MobileUserSummary ticket={ticket} visit_date={dateRedux?.visit_date} />

                                            <div style={{padding:'12px 24px',}}>
                                                <Row gutter={[12, 12]}>
                                                    <Col span={12}>
                                                        <Button
                                                        size='large'
                                                        // type='primary'
                                                        style={{width:'100%', }}
                                                        onClick={() => changeNextStep(state?.step - 1)}
                                                        >
                                                            Back
                                                        </Button>
                                                    </Col>
                                                    <Col span={12}>
                                                        <DateCheckoutButton ticket_unique_no={ticket?.unique_no} />
                                                    </Col>
                                                </Row>
                                    
                                                
                                            </div>
                                        </>
                                    )
                                }
                                
                            </div>
                        </Drawer>
                    </>
                )
            }
            
        </>
    );
}

export default MobileBottomLayout;