import React, { useEffect } from 'react';
import dayjs from 'dayjs';
import { Button, Calendar, Col, DatePicker, Row, Space } from 'antd';
import moment from 'moment';
import { useSelector } from 'react-redux';
import PreferenceList from './preference_list';
import { ArrowLeftOutlined, CaretDownFilled, CloseCircleFilled, LeftOutlined, RightOutlined } from '@ant-design/icons';

const PurchaseTicketDatePicker = ({ changeDate, park, is_screen, defaultValue }) => {
    const purchaseRedux = useSelector(state => state?.purchase);
    const ticketRedux = useSelector(state => state?.ticket);
    const ticket_type = park?.ticket_types?.find(x => x?.ticket_type_id == purchaseRedux?.ticket_type_id);
    const disabledDate = (current) => {
        //get advance
        const park_applicable_ticket_type = ticket_type?.ParkApplicableTicketType;
        const advance_day = park_applicable_ticket_type?.advance_day;
        const date_range = park_applicable_ticket_type?.date_range;
        const custom_format = "YYYY-MM-DD";
        let custom_date = moment().format(custom_format);
        const previous_date = current < moment(custom_date, custom_format);

        //get all maintenance date
        const maintenances = park?.maintenance_frequencies ?? [];
        let date_range_arr = [];
        let frequence_disabled = false;
        
        for(const maintenance of maintenances){
            const start_date = maintenance?.ParkMaintenance?.start_date;
            const end_date = maintenance?.ParkMaintenance?.end_date;
            const maintenance_frequency = (maintenance?.code === 'DL') ? // days calculate differently
            (current.format(maintenance?.format)) :
            (moment(start_date)?.format(maintenance?.format));

            if(end_date){
                if(
                    (current.format(custom_format) >= moment(start_date).format(custom_format)) &&
                    (current.format(custom_format) <= moment(end_date).format(custom_format))
                ){
                    frequence_disabled = (current.format(maintenance?.format) == maintenance_frequency)
                }
                
            }else{
                if(current.format(custom_format) >= moment(start_date).format(custom_format)){
                    frequence_disabled = (current.format(maintenance?.format) == maintenance_frequency);
                }
            }

            //if date range, then previous date also need to disable
            if(date_range){
                // if current add date range in between frequency
                // then disable it
                let date_range_disable_arr = [];
                for(let i = 1; i <= date_range; i++){
                    const current_add_date_range = (current)?.add(i, 'days').format(maintenance?.format);
                    const is_within_maintainence_date = current_add_date_range == maintenance_frequency;
                    date_range_disable_arr.push(is_within_maintainence_date);
                }

                const is_disabled = (date_range_disable_arr?.find(x => x == true)) ?? false;
                date_range_arr?.push(is_disabled);
            }
        }
        const date_range_disabled = date_range_arr?.find(x => x === true) ?? false;

        let is_advance_day = false;
        if(!ticketRedux?.special_link){
            if(advance_day){
                // disabled day before advance day
                const disable_end = ((moment(custom_date))?.add(advance_day, 'days'))?.format(custom_format);
                const today = current.format(custom_format);
                const is_between_disable = moment(today)?.isBetween(custom_date, disable_end, undefined, '[)');
                is_advance_day = is_between_disable;
            }
        }

        const disabled = date_range_disabled || frequence_disabled || is_advance_day || previous_date;
        return disabled;
    }

    return (
        <>
            {
                is_screen ? 
                (
                    <>
                        <Calendar
                        headerRender={({ value, type, onChange, onTypeChange, }) => {
                            const date_item = dayjs(value);
                            const cur_month = date_item?.month();
                            const month_name = date_item?.format("MMM");
                            const year_name = date_item?.format("YYYY");
                            
                            return (
                                <>
                                    <div style={{width : '100%', padding : '12px 0',}}>
                                        <Row align='middle'>
                                            <Col span={4} style={{textAlign:'center',}}>
                                                <Button
                                                onClick={() => {
                                                    // go to next month
                                                    const now = value.clone()?.month(cur_month - 1);
                                                    onChange(now);
                                                }}
                                                type='text'
                                                icon={<LeftOutlined style={{color : 'var(--secondary-text-color)'}} />}
                                                />
                                            </Col>
                                            <Col span={16}>
                                                <div style={{textAlign:'center',}}>
                                                    <span style={{fontSize:16, fontWeight:500,}}>{`${month_name} ${year_name}`}</span>
                                                </div>
                                            </Col>
                                            <Col span={4} style={{textAlign:'center',}}>
                                                <Button
                                                onClick={() => {
                                                    // go to next month
                                                    const now = value.clone()?.month(cur_month + 1);
                                                    onChange(now);
                                                }}
                                                type='text'
                                                icon={<RightOutlined style={{color : 'var(--secondary-text-color)'}} />}
                                                />
                                            </Col>
                                        </Row>
                                    </div>
                                </>
                            )
                        }}
                        fullscreen={false}
                        disabledDate={disabledDate}
                        mode='month'
                        onChange={(v, ds) => changeDate(v)}
                        defaultValue={defaultValue}
                        // value={null}
                        />
                    </>
                )
                :
                (
                    <>
                        <DatePicker
                        format={'DD/MM/YYYY'}
                        inputReadOnly
                        disabledDate={disabledDate}
                        variant='borderless'
                        onChange={(v, ds) => changeDate(ds)}
                        value={purchaseRedux?.visit_date && dayjs(purchaseRedux?.visit_date, 'DD/MM/YYYY')} 
                        className='purchase-visit-date'
                        popupClassName='purchase-visit-date-dropdown'
                        size={'large'}
                        suffixIcon={(<CaretDownFilled style={{color:'var(--main-color)', fontSize:18, fontWeight:'bold'}} />) }
                        allowClear={false}
                        showTime={false}
                        />
                    </>
                )
            }
            
        </>
    );
}

export default PurchaseTicketDatePicker;